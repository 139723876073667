<template>
  <div class="train train-img-w" >
    <MyBreadcrumb></MyBreadcrumb>
    <div class="train-cont">
      <div class="left">
        <div class="left-top" >
          <div v-if="!infoLoading">
            <div class="tit">
              <span>{{$t('trainingcamp_train_second')}}{{curIndex + 1}}{{$t('trainingcamp_train_topic')}}</span>
              <span>{{ typesObj[dataInfo.datasetData.type]}}{{$t('trainingcamp_train_topic')}}</span>
              <span v-if="dataInfo.datasetData.type != 1 && dataInfo.datasetData.type != 2 && dataInfo.datasetData.type != 3">({{$t('trainingcamp_train_no_answer')}})</span>
            </div>
            <div class="name" v-html="dataInfo.datasetData.name" style="display: inline-block;">
              <!--          {{ dataInfo.datasetData.name }}-->
            </div>
            <div class="cont" v-if="dataInfo.datasetData.type == 1 || dataInfo.datasetData.type == 2 || dataInfo.datasetData.type == 3">
              <!--        单选-->
              <RadioGroup v-model="radio" vertical v-if="dataInfo.datasetData.type == 1 || dataInfo.datasetData.type == 3">
                <Radio :disabled="isResult" :label="item.id" class="option"  v-for="(item,index) in dataInfo.datasetDataOptions" :key="item.id">
                  <div  style="display: inline-block"><p style="display: flex;justify-content: flex-start"><span>{{String.fromCharCode(index+65)}}:</span><span style="display: inline-block;">
                    <Richtxt :dataObj="{val:item.name}" :isInline="true" :isDisabled="true"></Richtxt>
                  </span></p></div>
                </Radio>
              </RadioGroup>
              <!--          多选-->
              <CheckboxGroup v-model="checkbox" v-if="dataInfo.datasetData.type == 2">
                <Checkbox :disabled="isResult" :label="item.id" class="checkoutOption"  v-for="(item,index) in dataInfo.datasetDataOptions" :key="item.id">
                  <div  style="display: inline-block"><p style="display: flex;justify-content: flex-start"><span>{{String.fromCharCode(index+65)}}:</span><span  style="display: inline-block;">
                    <Richtxt :dataObj="{val:item.name}" :isInline="true" :isDisabled="true"></Richtxt>
                  </span></p></div>
                </Checkbox>
              </CheckboxGroup>
            </div>
            <div class="cont mt10" v-else-if="dataInfo.datasetData.type == 4">
              <el-input class="cont-input" :disabled="isResult" size="large" v-model="item.myAnswer" v-for="(item,index) in dataInfo.datasetDataOptions" :key="item.id">
                <template slot="prepend">{{index + 1}}</template>
              </el-input>
            </div>
            <div class="cont" v-else-if="dataInfo.datasetData.type == 9">
              <el-upload :action="constant.URL + '/uploadfile/upload'"
                         :data="{
                      type:3
                    }"
                         :headers="{
                      'access-token':Cookies.get(constant.tokenName)
                    }"
                         :on-success="uploadFileSuccess"
                         :show-file-list="false"
                         :disabled="isResult"
                         v-if="!isResult"
              >
                <div class="upload-btn">
                  <Button type="primary">上传文件</Button>
                </div>
              </el-upload>
              <Button type="primary" v-else @click="downLoad(uploadPath)">下载文件</Button>
              <p class="mt10" v-if="uploadName">文件地址：{{uploadPath}}</p>
            </div>
            <div class="cont" v-else>
              <Richtxt placeholder="请输入内容" :isDisabled="isResult" :height="150" :dataObj="{val:shortAnswer}" @saveData="saveAnswer"></Richtxt>
            </div>
            <div class="bot">
              <div class="btn">
                <Button @click="pre">{{$t('trainingcamp_train_pre')}}</Button>
                <Button @click="next">{{$t('trainingcamp_train_next')}}</Button>
                <Button type="success" @click="isRight" v-if="(dataInfo.datasetData.type == 8 || dataInfo.datasetData.type == 9 || dataInfo.datasetData.type == 10) && isMark && dataInfo.userData && dataInfo.userData.is_check == '0'">正确</Button>
                <Button type="error" @click="isWrong" v-if="(dataInfo.datasetData.type == 8 || dataInfo.datasetData.type == 9 || dataInfo.datasetData.type == 10) && isMark && dataInfo.userData && dataInfo.userData.is_check == '0'">错误</Button>
              </div>
              <div class="op" v-if="isResult">
                <div @click="collect">
                  <img src="../../assets/images/icon/star_active.png"  v-if="dataInfo.userCollect.id" />
                  <img src="../../assets/images/icon/star.png" v-else />
                  <span>{{ $t('trainingcamp_train_collection') }}</span>
                </div>
                <div @click="correction">
                  <img src="../../assets/images/icon/mark.png" />
                  <span>{{ $t('trainingcamp_train_correction') }}/{{ $t('trainingcamp_train_feedback') }}</span>
                </div>
                <!--                <div @click="feedback">-->
                <!--                  <img src="../../assets/images/icon/report.png" />-->
                <!--                  <span>{{ $t('trainingcamp_train_feedback') }}</span>-->
                <!--                </div>-->
              </div>
            </div>
          </div>
          <Spin fix v-else></Spin>
        </div>
        <div class="analysis" v-if="isResult && dataInfo.datasetData">
          <div class="answer" v-if="dataInfo.datasetData.type == 1 || dataInfo.datasetData.type == 2 || dataInfo.datasetData.type == 3">
            {{ $t('trainingcamp_train_stu_answer') }}：<span :class="resultInfo.stuAnswer == resultInfo.correctAnswer ? 'green' : 'red'">{{resultInfo.stuAnswer}}</span>
          </div>
          <div class="answer" v-if="resultInfo.correctAnswer && (dataInfo.datasetData.type == 1 || dataInfo.datasetData.type == 2 || dataInfo.datasetData.type == 3 || dataInfo.datasetData.type == 4)">
            {{ $t('trainingcamp_train_correct_answer') }}：<span class="blue">{{resultInfo.correctAnswer}}</span>
          </div>
          <div class="cont">
            <span>{{ $t('trainingcamp_train_analysis') }}：</span><span style="display: inline-block;" v-html="resultInfo.explain"></span>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="submit-papers" v-if="!isResult">

          <div class="submit-btn" v-if="trainUserStatus == 2 || trainUserStatus == 4 || trainUserStatus == 5">已提交</div>
          <div class="submit-btn" @click="submitPapers" v-else>提交</div>
          <p>{{$t('trainingcamp_exam_remaining_time')}}</p>
          <p class="submit-time"  v-if="trainUserStatus == 2 || trainUserStatus == 4 || trainUserStatus == 5">00:00:00</p>
          <p class="submit-time" v-else>{{surplusTime.hours ? (surplusTime.hours < 10 ? '0' + surplusTime.hours : surplusTime.hours) : '00'}}:{{surplusTime.minutes ? (surplusTime.minutes < 10 ? '0' + surplusTime.minutes : surplusTime.minutes)  : '00'}}:{{surplusTime.seconds ? (surplusTime.seconds < 10 ? '0' + surplusTime.seconds : surplusTime.seconds) : '00'}}</p>
        </div>
        <div class="right-top" >
          <div class="tit">
            <div>
              {{ $t('trainingcamp_train_answer_sheet') }}
              <!--              <Tooltip>-->
              <!--                <Icon type="ios-help-circle-outline" />-->
              <!--                <div slot="content">-->
              <!--                  <p>{{ $t('trainingcamp_exam_blue') }}</p>-->
              <!--                  <p>{{ $t('trainingcamp_exam_white') }}</p>-->
              <!--                  <p>{{ $t('trainingcamp_exam_green') }}</p>-->
              <!--                </div>-->
              <!--              </Tooltip>-->
            </div>
          </div>

          <div class="answer-card">
            <div v-if="!answerCardLoading" class="list">
              <div :class="{active:curData.id == item.id,error:isResult && item.userData && item.userData.is_right == '0',corret:isResult && item.userData && item.userData.is_right == '1',complete:item.userData && item.userData.answer && !isResult,mark:isResult && item.userData && item.userData.is_check == '0' && isMark } " v-for="(item,index) in answerCardList" :key="item.id" @click="changeAnswerCard(item,index)"><Icon type="ios-lock" v-if="item.is_free == '1'" />
                <span>{{index + 1}}</span>
                <img src="../../assets/images/new_icon/yue.png" v-if="isMark && item.userData && item.userData.is_check == '0'" width="16" height="16" />
              </div>
            </div>
            <Spin fix v-else></Spin>
          </div>
        </div>
      </div>
    </div>


    <Modal
        v-model="correctionModal"
        :title="$t('trainingcamp_train_correction') + '/' + $t('trainingcamp_train_feedback')"
        width="70%"
    >
      <div class="correction">
        <div class="tit">
          <div class="left">
            <p class="data-tit">纠错标题</p>
          </div>
          <div class="right" style="border: none">
            <div class="right-list">
              <div :class="item.id == correctionTitle ? 'active' : ''" v-for="(item,index) in titleList" :key="index" @click="changeCorrectionTit(item.id)">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div class="tit">
          <div class="left">
            <p class="data-tit">纠错内容</p>
          </div>
          <div class="right">
            <Richtxt v-if="correctionModal" placeholder="请您描述具体错误原因。" :height="150" :dataObj="{val:formItem.feedback}" @saveData="saveRichData"></Richtxt>
          </div>
        </div>
        <p class="type"><strong>{{ $t('trainingcamp_train_correction_type') }}:</strong>{{ correctionInfo.datasetData && typesObj[correctionInfo.datasetData.type]}}</p>
        <div class="tit">
          <div class="left">
            <p class="data-tit">{{ $t('trainingcamp_train_correction_title') }}</p>
          </div>
          <div class="right">
            <Richtxt v-if="correctionInfo.datasetData" :height="150" :dataObj="{val:correctionInfo.datasetData && correctionInfo.datasetData.name}" @saveData="(data)=>saveCurData(data,correctionInfo.datasetData,'name')" key="feedback"></Richtxt>
          </div>
        </div>
        <div class="option-item" v-for="(item,index) in correctionInfo.datasetDataOptions" :key="item.id">
          <div class="left">
            <p class="data-tit">{{optionNameList[index]}}</p>
            <p class="correct" :class="item.is_correct == '1' ? 'active' : ''" @click="changeCorrect(item)">{{item.is_correct == '1' ? $t('trainingcamp_train_correction_correct') : $t('trainingcamp_train_correction_set_correct')}}</p>
            <p class="dele" v-if="correctionInfo.datasetDataOptions.length > 1" @click="deleOption(index)">{{ $t('trainingcamp_train_correction_delete') }}</p>
          </div>
          <div class="right">
            <Input v-if="correctionInfo.datasetData && correctionInfo.datasetData.type == '4'" v-model="item.name" type="textarea" placeholder="请输入答案" />
            <Richtxt :dataObj="{val:item.name}" :height="150" @saveData="(data)=>saveCurData(data,item,'name')"></Richtxt>
          </div>
        </div>
        <Button type="primary" @click="addOption">{{ $t('trainingcamp_train_correction_add_option') }}</Button>
        <div class="analysis">
          <div class="left">
            <p class="data-tit">{{ $t('trainingcamp_train_correction_analysis') }}</p>
          </div>
          <div class="right">
            <Richtxt v-if="correctionInfo.datasetData" @saveData="(data)=>saveCurData(data,correctionInfo.datasetData,'explain')" :height="150" :dataObj="{val:correctionInfo.datasetData && correctionInfo.datasetData.explain}" ></Richtxt>
          </div>
        </div>
      </div>
      <div slot="footer">
        <Button @click="correctionModal=false">{{ $t('trainingcamp_train_feedback_cancel') }}</Button>
        <Button type="primary" @click="confirmCorrection">{{ $t('trainingcamp_train_feedback_confirm') }}</Button>
      </div>
    </Modal>
    <Modal
        v-model="feedbackModal"
        :title="$t('trainingcamp_train_feedback')"
        width="70%"
    >
      <Form ref="formValidate" :model="formItem" :label-width="80" :rules="ruleValidate">
        <FormItem :label="$t('trainingcamp_train_feedback_tit')" prop="title">
          <Input v-model="formItem.title" :placeholder="$t('trainingcamp_train_feedback_tit_placeholder')"></Input>
        </FormItem>

        <FormItem :label="$t('trainingcamp_train_feedback_cont')" prop="feedback">
          <div class="feedback-richtxt">
            <Richtxt v-if="feedbackModal" :dataObj="{val:formItem.feedback}" @saveData="saveRichData"></Richtxt>
          </div>
          <!--        <Input v-model="formItem.feedback" :maxlength="2000"  type="textarea"  placeholder="请输入反馈内容"></Input>-->
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="feedbackModal=false">{{ $t('trainingcamp_train_feedback_cancel') }}</Button>
        <Button type="primary" @click="confirm">{{ $t('trainingcamp_train_feedback_confirm') }}</Button>
      </div>
    </Modal>
    <Modal
        v-model="confirmSubmitModal"
        :title="$t('trainingcamp_exam_confirm_submit')"
        :mask-closable="false"
        :closable="false"
    >
      <div class="confirm-submit-txt" v-if="submitMessage">{{submitMessage}}</div>
      <div class="confirm-submit-txt">确认交卷后，{{typeName}}结束。</div>
      <div slot="footer">
        <Button @click="confirmSubmitModal=false" v-if="time > 0">{{ $t('trainingcamp_train_feedback_cancel') }}</Button>
        <Button type="primary" @click="confirmSubmit">{{ $t('trainingcamp_train_feedback_confirm') }}</Button>
      </div>
    </Modal>
    <Modal
        v-model="examOverModal"
        title="考试已结束"
        :mask-closable="false"
        :closable="false"
    >
      <div class="confirm-submit-txt">考试已结束</div>
      <div slot="footer">
        <Button type="primary" @click="confirmSubmit('examOver')">{{ $t('trainingcamp_train_feedback_confirm') }}</Button>
      </div>
    </Modal>
    <Modal
        v-model="similarDataModal"
        :title="'举一反三' + (similarDataIndex + 1) + '/'+similarData.length"
        :footer-hide="true"
        width="1000px"
    >
      <DataDetail :similarData="similarData[similarDataIndex]" :typesObj="typesObj" :levelObj="levelObj"></DataDetail>
      <div class="next-btn" v-if="similarDataIndex > 0" @click="similarDataIndex--" style="margin-right: 20px">上一题</div>
      <div class="next-btn" v-if="similarDataIndex < similarData.length - 1" @click="similarDataIndex++">下一题</div>
    </Modal>
    <Modal
        v-model="checkScoreModal"
        title="填写得分分数"
    >
      <div class="confirm-submit-txt" v-if="dataInfo.datasetData">该题总分数为  {{scoreObj[dataInfo.datasetData.type]}}  分</div>
      <div style="text-align: center;margin-top: 20px">
        <el-input v-model="checkScore" @input="checkScoreLen" size="large" type="number" placeholder="请填写分数" style="width: 300px;" />
      </div>
      <div slot="footer">
        <Button @click="checkScoreModal=false" >取消</Button>
        <Button type="primary" @click="confirmCheckScore">确定</Button>
      </div>
    </Modal>
    <ActivationModal v-if="activationModal" :categoryId="categoryId" @close="closeActiveModal" @success="getAnswerCard"></ActivationModal>
  </div>
</template>

<script>
import FileSaver from "file-saver";
import util from '@/utils/tools.js';
import Richtxt from '../../components/production/richtxt.vue';
import ActivationModal from '@/components/activationModal.vue';
import DataDetail from '../trainingcamp/components/exam/dataDetail'
import Cookies from "js-cookie";
// import Breadcrumb from '@/components/breadcrumb.vue';
export default {
  name: "train.vue",
  data(){
    return {
      Cookies:Cookies,
      radio:'',
      switch1:true,
      value:'0',
      categoryId:'',
      productId:'', //运营产品 ID ，小程序 1，阿里-每日一练 2  其他 1
      answerCardList:[],
      answerCardLoading:true,
      curData:{}, //当前选中数据id
      curIndex:0,
      dataInfo:{},  //数据详情
      infoLoading:true,
      typesObj:{},
      optionList:['A','B','C','D','E','F','G','H','I','J','K','L'],
      startTime:'',
      endTime:'',
      resultInfo:{
        explain:'',
        stuAnswer:'',
        correctAnswer:''
      },
      checkbox:[],
      rightCount:0, //正确数量
      haveFinished:0, //完成数量
      totalCount:0,
      feedbackModal:false,
      formItem:{
        title:'',
        feedback:'',
      },
      ruleValidate: {
        title:[{ required: true, message: this.$t('trainingcamp_train_feedback_tit_placeholder'), trigger: 'blur' }],
        feedback: [
          { required: true, message: this.$t('trainingcamp_train_feedback_cont_placeholder'), trigger: 'blur' }
        ]
      },
      dataSort:'',
      redo:'0',
      trainUserId:'', //用户当前题的id 如果重做会重新生成一个id
      trainId:'', //每个题库只有一个trainId
      correctionModal:false,
      optionNameList:[this.$t('trainingcamp_train_correction_option_a'),this.$t('trainingcamp_train_correction_option_b'),this.$t('trainingcamp_train_correction_option_c'),this.$t('trainingcamp_train_correction_option_d'),this.$t('trainingcamp_train_correction_option_e'),this.$t('trainingcamp_train_correction_option_f'),this.$t('trainingcamp_train_correction_option_g')],
      correctionInfo:{},
      confirmSubmitModal:false,  //确认交卷弹窗
      trainInfo:{},
      surplusTime:{},
      attentionModal:false,
      time:0,
      isResult:false, //结果页
      trainResult:{},
      useTime:0,
      examPaperStartTime:'',
      examPaperFinishTime:'',
      handExamPaperStartTime:'',
      handExamPaperFinishTime:'',
      type:'',
      path:'/trainingcamp/examrecord',
      resultTit:this.$t('trainingcamp_exam_result'),
      isSelf:false, //是否为自测模考
      isMatch:false,  //是否为竞赛
      titleList:[
      ],
      correctionTitle:'',
      activationModal:false,
      typeName:'模考',
      generateId:'',
      isEvaluation:false,
      evaluationCategory:'',
      evaluationType:'',
      timer:null,
      onlyWrong:0,
      startNum:0,
      siteId:'',
      userId:'',
      isEvaluationReport:false,
      similarDataModal:false,
      similarData:[],
      similarDataIndex:0,
      levelObj:{},
      uploadName:'',
      uploadPath:'',
      shortAnswer:'',
      submitMessage:'',
      isMark:false,
      checkScoreModal:false,
      scoreObj:{},
      checkScore:'',
      trainUserStatus:'',
      from:'',
      examineId:'',
      userInfo:{},
      userId:'',
      examOverModal:false,
      courseId:'',
      generateId:'',
    }
  },
  components:{
    Richtxt,
    ActivationModal,
    DataDetail
  },
  created(){
    this.trainId = this.$route.query.id || '';
    this.isResult = this.$route.query.isResult ? true : false;
    this.isMark = this.$route.query.isMark || '';
    this.userId = this.$route.query.userId || '';

    this.generateId = this.$route.query.generateId || '';
    this.courseId = this.$route.query.courseId || '';


    //单独处理面包屑  因为考试与结果页用了同一页面
    if(this.isResult){
      let breadCrumbList = JSON.parse(localStorage.getItem('breadCrumbList'));
      breadCrumbList[breadCrumbList.length -1].title = '查看结果';

      localStorage.setItem('breadCrumbList',JSON.stringify(breadCrumbList));
      this.$store.commit('changeBreadCrumbList',breadCrumbList);
    }
    this.getAnswerCard();

    window.addEventListener('focus',this.getUserInfo)
  },
  mounted(){
    // if(!this.isResult){
    //   this.attentionModal = true;
    // }
  },
  methods:{
    getUserInfo(){
      let params = {
        action:'trainUserInfo',
        train_id:this.trainId
      };
      this.api.dataset.trainExecute(params).then((res)=>{
        this.time = res.time_countdown > 0 ? res.time_countdown : 0;
        if(this.time >= '0' && res.train_user.start_time != '0') {
          this.surplusTime = util.timeFormate(this.time);
        }
      })
    },
    getCountdownTime(){
      let params = {
        action:'trainUserInfo',
        train_id:this.trainId
      };
      this.api.dataset.trainExecute(params).then((res)=>{
        this.time = res.time_countdown;
        if(this.time > '0' && res.train_user.start_time != '0' ) {
          this.surplusTime = util.timeFormate(this.time);
          this.timer = setInterval(()=>{
            if(this.time <= 0){
              this.submitPapers();
              clearInterval(this.timer);
              return;
            }
            this.time--;
            this.surplusTime = util.timeFormate(this.time);
          },1000)
        }else{
          this.time = 0;
          this.surplusTime = util.timeFormate(this.time);
        }
      })
    },
    closeActiveModal(){
      this.activationModal = false;
    },
    changeCorrectionTit(data){
      this.correctionTitle = data;
    },
    getTrainDetail(){
      let params = {
        id:this.trainId,
      }
      this.api.dataset.trainDataDetail(params).then((res)=>{
        this.trainInfo = res.info;
        let oDate = new Date();
        this.time = res.info.end_time - parseInt(oDate.getTime()/1000);
        this.surplusTime = util.timeFormate(this.time);

        this.timer = setInterval(()=>{
          if(this.time <= 0){
            this.submitPapers();
            clearInterval(this.timer);
            return;
          }
          this.time--;
          this.surplusTime = util.timeFormate(this.time);
        },1000)
      })
    },

    getAnswerCard(index){
      let params = {
        action:'testList',
        only_wrong:this.onlyWrong,
        user_id:this.userId
      };
      if(this.trainId){ //课程互动
        params.train_id = this.trainId;
      }else{  //学情分析 - 学生详情 - 答题卡
        params.generate_id = this.generateId;
        params.from_id = this.courseId;
        params.from_type = 5;
        params.data_rule = 1;
        params.data_type = 10;
      }



      // let params = {
      //   action:'testList',
      //   // category_id:this.categoryId,
      //   product_id:this.productId,
      //   data_rule:this.dataSort,
      //   redo:this.redo,
      //   train_user_id:this.trainUserId,
      //   train_id:this.trainId,
      // };
      // if(this.generateType == 2){ //自有题库
      //   params.group_id = this.categoryId;
      // }else{
      //   if(this.dataSort == 3){
      //     if(this.trainId){
      //       params.train_id = this.trainId;
      //     }
      //   }else{
      //     params.category_id = this.categoryId;
      //     if(this.trainId){
      //       params.train_id = this.trainId;
      //     }
      //   }
      //   // params.category_id = this.categoryId;
      // }
      // if(this.dataType){
      //   params.data_type = this.dataType;
      // }
      // if(this.generateId){
      //   params.generate_id = this.generateId;
      // }
      // if(this.fromId){
      //   params.from_id = this.fromId;
      // }
      // if(this.fromType){
      //   params.from_type = this.fromType;
      // }
      // if(this.courseType){
      //   params.type = this.courseType;
      // }


      this.activationModal = false;
      this.answerCardLoading = true;
      this.api.dataset.trainExecute(params).then((res)=>{
        this.answerCardLoading = false;
        this.redo = '0';  //请求结束后还原
        this.trainInfo = res.train;
        this.userInfo = res.user;
        let scoreList = this.trainInfo.score_str.split(',');
        this.scoreObj = {
          1:scoreList[0],
          2:scoreList[1],
          3:scoreList[2],
          4:scoreList[3],
          8:scoreList[4],
          9:scoreList[5],
          10:scoreList[6],
        }
        this.courseId = res.course_interact_task.course_id;


        if(res.data_list.length){
          this.answerCardList = res.data_list;
          this.typesObj = res.types;


          if(index){
            this.curIndex = index;
            this.curData = this.answerCardList[index];
          }else{
            this.curIndex = Number(this.startNum) ? Number(this.startNum) : 0;
            this.curData =  Number(this.startNum) ? this.answerCardList[Number(this.startNum)] : this.answerCardList[0];
          }



          this.trainResult = res.train_user;
          this.categoryId = res.train_user.category_id;

          this.titleList = [];
          for(let name in res.data_correction_types){
            this.titleList.push({
              name:res.data_correction_types[name],
              id:name,
            })
          }

          this.getInfo(); //获取数据详情
        }else{
          this.$Message.warning(this.$t('trainingcamp_train_no_data'));
        }
        this.trainUserId = res.train_user.id;
        this.trainUserStatus = res.train_user.status;
        this.trainId = res.train.id;
        if(!this.isResult){
          let params = {
            action:'trainUserStart',
            train_user_id:this.trainUserId,
          };

          this.api.dataset.trainExecute(params).then((res)=>{
            // this.formatInfoData(res.data_list[0]);
            this.attentionModal = false;
            // this.getAnswerCard();
            this.getCountdownTime();



            if(res.is_finish == '1'){  //如果一进来就结束了  直接提示
              this.examOverModal = true;
              return;
            }

          })
        }

      }).catch((err)=>{
        this.answerCardLoading = false;
      })
    },
    getInfo(){  //获取详情数据
      let params = {
        action:'testListInfo',
        product_id:this.productId,
        group_id:this.curData.train_id,
        data_id:this.curData.data_id,
        train_user_id:this.trainUserId,
        train_id:this.trainId,
      };
      if(this.from == 'analysis'){
        params.site_id = '-1';
        params.user_id = '-1';
      }
      if(this.userId){
        params.user_id = this.userId
      }
      this.infoLoading = true;
      this.api.dataset.trainExecute(params).then((res)=>{
        this.infoLoading = false;
        this.dataInfo = res[0];
        this.formatInfoData(res[0]);

        this.startTime = this.getTime();
      }).catch((err)=>{
        this.infoLoading = false;
      })
    },
    getTime(){
      let oDate = new Date();
      let time = Math.floor(oDate.getTime() / 1000);

      return time
    },
    changeAnswerCard(data,index){
      if(data.is_free == '1'){

        // console.log('1212121212')
        this.activationModal = true;
        return;
      }
      if(!this.isResult){
        this.commitAnswer(this.curIndex);  //先提交数据
      }

      this.curData = data;
      this.curIndex = index;

      this.getInfo(); //获取数据详情
    },
    next(){ //下一题

      if(!this.isResult){
        this.commitAnswer(this.curIndex);  //先提交数据
      }
      if(this.curIndex == this.answerCardList.length - 1){
        this.$Message.warning(this.$t('trainingcamp_train_last_test'));
        return;
      }
      if(this.answerCardList[this.curIndex + 1].is_free == '1'){
        // this.$Message.warning('暂无权限');
        this.activationModal = true;
        return;
      }
      this.curIndex = this.curIndex + 1;
      this.curData = this.answerCardList[this.curIndex];

      this.getInfo(); //获取数据详情
    },
    pre(){  //上一题
      if(!this.isResult){
        this.commitAnswer(this.curIndex);  //先提交数据
      }
      if(this.curIndex == 0){
        this.$Message.warning(this.$t('trainingcamp_train_first_test'));
        return;
      }
      if(this.answerCardList[this.curIndex - 1].is_free == '1'){
        // this.$Message.warning('暂无权限');
        this.activationModal = true;
        return;
      }
      this.curIndex = this.curIndex - 1;
      this.curData = this.answerCardList[this.curIndex];

      this.getInfo(); //获取数据详情
    },
    submitPapers(){ //交卷
      this.commitAnswer(this.curIndex,()=>{
        let unanswered = this.answerCardList.filter((item)=>{
          return !item.userData;
        }).length
        if(unanswered){
          this.submitMessage = '还有未答题，确认交卷吗？';
        }else{
          this.submitMessage = '';
        }
      });
      console.log(this.answerCardList,'answerCardList')
      this.confirmSubmitModal = true;
    },
    confirmSubmit(type){  //确认交卷
      if(type == 'examOver'){ //一进来就结束了
        if(this.courseId){
          this.$router.push({
            path:'/trainingcamp/chapters',
            query:{
              tab:'WeeklySchedule',
              courseId:this.courseId
            }
          })
        }else{
          this.$router.push({
            path:'/home_student',
          })
        }

      }else{
        let params = {
          action:'trainUserInfo',
          train_id:this.trainId
        };
        this.api.dataset.trainExecute(params).then((res)=>{
          if(res.time_countdown < 0){
            if(this.courseId){
              this.$router.push({
                path:'/trainingcamp/chapters',
                query:{
                  tab:'WeeklySchedule',
                  courseId:this.courseId
                }
              })
            }else{
              this.$router.push({
                path:'/home_student',
              })
            }
          }else{
            let submitParams = {
              action:'trainUserFinish',
              product_id:this.productId,
              train_user_id:this.trainUserId,
            };

            this.api.dataset.trainExecute(submitParams).then((res)=>{
              // this.formatInfoData(res.data_list[0]);
              this.confirmSubmitModal = false;

              if(this.courseId){
                this.$router.push({
                  path:'/trainingcamp/chapters',
                  query:{
                    tab:'WeeklySchedule',
                    courseId:this.courseId
                  }
                })
              }else{
                this.$router.push({
                  path:'/home_student',
                })
              }
            })
          }
        })
      }


    },
    commitAnswer(index,fn){ //提交答案

      this.endTime = this.getTime();
      let date = this.endTime - this.startTime;

      let answer_data = [
        {
          id:this.curData.data_id,
          use_time:date
        }
      ];
      if(this.dataInfo.datasetData.type == '1' || this.dataInfo.datasetData.type == '3'){  //1单选 3判断
        if(!this.radio && !this.dataInfo.userData.id){
          // this.$Message.warning(this.$t('trainingcamp_train_select_answer'));
          return;
        }
        if(this.radio){
          // let data = this.radio.substring(3);
          // let answer = this.dataInfo.datasetDataOptions.filter((item)=>{
          //   return item.name == data;
          // })[0].id;
          answer_data[0].answer = this.radio;
        }
      }
      if(this.dataInfo.datasetData.type == '2'){  //2 多选
        if(!this.checkbox.length && !this.dataInfo.userData.id){
          // this.$Message.warning(this.$t('trainingcamp_train_select_answer'));
          return;
        }
        if(this.checkbox.length){
          // let arr = this.checkbox.map((item)=>{
          //   return item.substring(3);
          // })
          //
          // let answer = [];
          // this.dataInfo.datasetDataOptions.forEach((item)=>{
          //   let num = arr.findIndex((sItem)=>{
          //     return sItem == item.name;
          //   });
          //   if(num > -1){
          //     answer.push(item.id);
          //   }
          // })
          answer_data[0].answer = this.checkbox.join(',');
        }
      }

      if(this.dataInfo.datasetData.type == '4'){
        let len = this.dataInfo.datasetDataOptions.filter((item)=>{
          return item.myAnswer;
        }).length;
        console.log(len,this.dataInfo.userData.id,'ddddd')
        if(!len && !this.dataInfo.userData.id){
          return;
        }else{
          let answer = this.dataInfo.datasetDataOptions.map((item)=>{
            return item.myAnswer;
          })
          answer_data[0].answer = answer.join(',');
        }

      }
      if(this.dataInfo.datasetData.type == '9'){  //实操
        if(!this.uploadPath && !this.dataInfo.userData.id){
          // this.$Message.warning(this.$t('trainingcamp_train_select_answer'));
          return;
        }
        answer_data[0].answer = this.uploadPath;
      }
      if(this.dataInfo.datasetData.type == '8' || this.dataInfo.datasetData.type == '10'){  //简答、填空、实验
        if(!this.shortAnswer && !this.dataInfo.userData.id){
          // this.$Message.warning(this.$t('trainingcamp_train_select_answer'));
          return;
        }
        answer_data[0].answer = this.shortAnswer;
      }

      let params = {
        action:'exerciseSubmit',
        product_id:this.productId,
        use_time:date,
        answer_data:JSON.stringify(answer_data),
        train_user_id:this.trainUserId,
        train_id:this.trainId,
      };

      this.api.dataset.trainExecute(params).then((res)=>{
        // this.formatInfoData(res.data_list[0]);
        if(this.isMatch){
          if(res.train_user.status == 2){
            this.$Message.success('已交卷');
            this.$router.go(-1)
          }
        }
        if(!this.answerCardList[index].userData){
          this.$set(this.answerCardList[index],'userData', {});
        }
        this.$set(this.answerCardList[index].userData,'answer',answer_data[0].answer);

        fn && fn();
      })
    },
    formatInfoData(data,type){
      this.resultInfo.explain = data.datasetData.explain;

      if(this.dataInfo.datasetData.type == '1' || this.dataInfo.datasetData.type == '3') {  //1单选 3判断

        let index = data.datasetDataOptions.findIndex((item) => {
          return data.userData.answer == item.id
        });
        let correctIndex = data.datasetDataOptions.findIndex((item) => {
          return item.is_correct == 1;
        });
        if(this.isResult){ //查看结果
          this.$set(this.resultInfo, 'stuAnswer', this.optionList[index]);
          this.$set(this.resultInfo, 'correctAnswer', this.optionList[correctIndex]);
        }
        if(this.dataInfo.userData.id){
          this.radio = data.datasetDataOptions[index].id;

        }else{
          this.radio ='';
        }
      }

      if(this.dataInfo.datasetData.type == '2') {  //2多选
        let answerArr = data.userData.answer && data.userData.answer.split(',');
        //学生答案的index  正确答案的index
        let stuAnswerIndex = [];
        let correctAnswerIndex = [];

        data.datasetDataOptions.forEach((item,index)=>{
          if(answerArr){
            let num = answerArr.findIndex((sItem)=>{
              return sItem == item.id
            });
            if(num > -1){
              stuAnswerIndex.push(index);
            }
          }
          if(item.is_correct == '1'){
            correctAnswerIndex.push(index);
          }
        })

        let stuAnswer = [];
        let checkoutArr = [];
        let correctCheckoutArr = [];

        if(stuAnswerIndex.length){
          stuAnswerIndex.forEach((item)=>{
            stuAnswer.push(this.optionList[item])
            checkoutArr.push(data.datasetDataOptions[item].id)
          })
        }


        let correctAnswer = [];

        correctAnswerIndex.forEach((item)=>{
          correctAnswer.push(this.optionList[item]);
          correctCheckoutArr.push(data.datasetDataOptions[item].id)
        });

        if(stuAnswer.length){
          this.$set(this.resultInfo, 'stuAnswer', stuAnswer.join(','));
        }
        if(correctAnswer.length){
          this.$set(this.resultInfo, 'correctAnswer', correctAnswer.join(','));
        }

        if(this.dataInfo.userData.id){
          this.checkbox = checkoutArr;
        }else{
          this.checkbox = [];
        }

      }
      if(this.dataInfo.datasetData.type == '9') { //实操

        if(data.userData.answer){
          let urlArr = data.userData.answer.split('/');
          this.uploadName = urlArr[urlArr.length -1];
          this.uploadPath = data.userData.answer;
        }else{
          this.uploadName ='';
          this.uploadPath = '';
        }

      }
      if(this.dataInfo.datasetData.type == '4' ){
        let answer;
        this.$set(this.resultInfo, 'correctAnswer', '');
        if(data.userData.answer){
          answer = data.userData.answer.split(',');
          let correctAnswer = this.dataInfo.datasetDataOptions.map((item)=>{
            return item.name;
          }).join(',')
          this.$set(this.resultInfo, 'correctAnswer', correctAnswer);
        }
        data.datasetDataOptions.forEach((item,index)=>{
          if(answer[index]){
            this.$set(item,'myAnswer',answer[index]);
          }else{
            this.$set(item,'myAnswer','');
          }
        })
      }
      if(this.dataInfo.datasetData.type == '8' || this.dataInfo.datasetData.type == '10') { //简答、填空、实验
        this.shortAnswer = data.userData.answer;
      }
    },
    collect(){  //收藏
      let params = {
        action:'userCollect',
        product_id:this.productId,
        type:'0',
        data_id:this.curData.data_id,
        train_user_id:this.trainUserId,
        train_id:this.trainId,
      };
      this.api.dataset.trainExecute(params).then((res)=>{
        this.$Message.success(res);
        if(res == '取消收藏成功'){
          this.$set(this.dataInfo,'userCollect',{});
        }else{
          this.$set(this.dataInfo.userCollect,'id',1);
        }
      })
    },
    feedback(){
      this.feedbackModal = true;
    },
    confirm(){  //反馈确定
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          let params = {
            action:'feedback',
            product_id:this.productId,
            data_id:this.curData.data_id,
            title:this.formItem.title,
            content:this.formItem.feedback,
            train_user_id:this.trainUserId,
            train_id:this.trainId,
          };
          this.api.dataset.trainExecute(params).then((res)=>{
            this.$Message.success(this.$t('trainingcamp_train_feedback_success'));
            this.feedbackModal = false;
          })
        }
      })
    },
    saveRichData(data){
      this.formItem.feedback = data;
    },
    saveAnswer(data){
      this.shortAnswer = data;
    },
    correction(){
      this.correctionModal = true;
      this.correctionInfo = JSON.parse(JSON.stringify(this.dataInfo));
      this.correctionTitle = '';
      this.formItem.feedback = '';
    },
    saveCurData(curData,data,name){
      data[name] = curData;
    },
    changeCorrect(data){  //修改正确选项
      if(this.correctionInfo.datasetData.type == '1' || this.correctionInfo.datasetData.type == '3'){ //单选
        if(data.is_correct == '0'){
          this.correctionInfo.datasetDataOptions.forEach((item)=>{
            item.is_correct = '0';
          });
          this.$nextTick(()=>{
            data.is_correct = '1';
          })

          // console.log(data)
        }else{
          this.$nextTick(()=>{
            data.is_correct = '0';
          })
        }
      }else{
        data.is_correct == '0'  ? data.is_correct = '1' : data.is_correct = '0';
      }

    },
    addOption(){  //添加选项
      if(this.correctionInfo.datasetDataOptions.length == 6){
        this.$Message.warning(this.$t('trainingcamp_train_option_max'));
        return;
      }
      this.correctionInfo.datasetDataOptions.push({
        name:'',
        id:'0',
        is_correct:'0'
      })
    },
    deleOption(index){  //删除选项
      this.correctionInfo.datasetDataOptions.splice(index, 1);
    },
    confirmCorrection(){  //确认纠错
      if(!this.correctionTitle){
        this.$Message.warning('请输入纠错标题');
        return;
      }
      if(this.correctionTitle == 4 && !this.formItem.feedback){
        this.$Message.warning('请输入纠错内容');
        return;
      }

      // if(!this.correctionInfo.datasetData.name){  //标题
      //   this.$Message.warning(this.$t('trainingcamp_train_warning_tit'));
      //   return;
      // }
      // if(!this.correctionInfo.datasetDataOptions.length){ //选项
      //   this.$Message.warning(this.$t('trainingcamp_train_warning_add_option'));
      //   return;
      // }
      // let len = this.correctionInfo.datasetDataOptions.filter((item)=>{
      //   return item.is_correct == '1';
      // }).length
      // if(!len){ //正确选项
      //   this.$Message.warning(this.$t('trainingcamp_train_warning_select'));
      //   return;
      // }
      // for(let i=0;i<this.correctionInfo.datasetDataOptions.length;i++){
      //   if(!this.correctionInfo.datasetDataOptions[i].name){
      //     this.$Message.warning(this.$t('trainingcamp_train_warning_input_cont'));
      //     return;
      //     break;
      //   }
      // }
      // if(!this.correctionInfo.datasetData.explain){ //解析
      //   this.$Message.warning('请输入解析');
      //   return;
      // }

      if(this.correctionInfo.datasetData.type == 4){
        this.correctionInfo.datasetDataOptions.forEach((item)=>{
          item.is_correct = '1';
        })
      }

      let params = {
        data_id:this.correctionInfo.data_id,
        name:this.correctionInfo.datasetData.name,
        explain:this.correctionInfo.datasetData.explain,
        options:JSON.stringify(this.correctionInfo.datasetDataOptions),
        type:this.correctionInfo.datasetData.type,
        level:this.correctionInfo.datasetData.level,
        err_type:this.correctionTitle,
        err_message:this.formItem.feedback
      };

      this.api.dataset.correctionCreate(params).then((res)=>{
        this.correctionModal = false;
        this.$Message.success(this.$t('trainingcamp_train_correction_success'));
        // this.getInfo()
      })
    },
    goMyinfo(){
      this.$router.push({
        path:'/myinfo'
      })
    },
    checkSimilar(){
      this.similarDataModal = true;
      let params = {
        action:'dataSimilarList',
        data_id:this.curData.data_id,
      };
      this.api.dataset.trainExecute(params).then((res)=>{
        this.similarData = res.data_list;
        this.levelObj = res.data_levels;
      })
    },
    uploadFileSuccess(response,file, fileList){  //上传文件成功
      this.uploadPath = response.data.info.upload_path;
      this.uploadName = response.data.info.name;
    },
    downLoad(data){
      let name = data.split('/');
      name = name[name.length -1].split('.');
      name = name[0] + '.' + name[1];
      FileSaver.saveAs(data,name);
    },
    confirmCheckScore(){
      this.checkScoreFn(1)
    },
    isRight(){
      this.checkScoreFn(1)
      // this.checkScoreModal = true;
      // this.checkScore = '';
    },
    isWrong(){
      this.checkScoreFn(0)
    },
    checkScoreFn(status){
      let params = {
        train_id:this.trainId,
        train_user_id:this.trainUserId,
        data_id:this.curData.data_id,
        is_right:status
      };
      if(status == 1){
        // if(this.checkScore > this.scoreObj[this.dataInfo.datasetData.type]){
        //   this.$Message.warning('输入分数不能大于总分');
        //   return;
        // }
        params.score = 1;
      }
      this.api.course.checkTrainUserData(params).then((res)=>{
        this.$Message.success('操作成功');
        this.checkScoreModal = false;
        this.curData.userData.is_check = '1';
        this.curData.userData.is_right = status;
        this.dataInfo.userData.is_check = '1';
        if(status == 1){  //正确 手动修改分数
          this.trainResult.score = Number(this.trainResult.score) + Number(this.checkScore);
        }

        //找后面有没有需要阅卷的题 如果有跳到此题如果没有不跳转
        let nextIndex;

        for(let i = this.curIndex + 1;i<this.answerCardList.length;i++){
          console.log(this.answerCardList[i].userData.is_check,'-----',i)
          if(this.answerCardList[i].userData && this.answerCardList[i].userData.is_check == 0){
            console.log(this.answerCardList[i].userData.is_check,'this.answerCardList[i].userData.is_check')
            nextIndex = i;
            break;
          }
        }

        if(nextIndex){

          this.curIndex = nextIndex;
          this.curData = this.answerCardList[nextIndex];

          this.getInfo(); //获取数据详情
        }


      })
    },
    checkScoreLen(){
      this.checkScore = util.oneDecimal(this.checkScore);
      console.log(this.checkScore,'========')
    }
  },
  destroyed(){
    clearInterval(this.timer);
    window.removeEventListener('focus',this.getUserInfo)
  }
}
</script>

<style scoped lang="scss">
.train{
  padding: 20px;
  font-size: 14px;

  .train-cont{
    display: flex;
    justify-content: space-between;
  }

  .left{
    flex: 1;
    margin-right: 20px;
    .left-top{
      padding:20px;
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;
      font-size: 16px;
      position: relative;

      .tit{

        height: 40px;
        line-height: 40px;
        font-size: 20px;
        font-weight: bold;
        >span:nth-child(2){
          margin-left: 20px;
          font-size: 16px;
          font-weight: normal;
        }
        >span:nth-child(3){
          margin-left: 5px;
          font-size: 12px;
          font-weight: normal;
        }
      }
      .name{
        margin:10px 0 ;
      }
      .cont{
        .option{
          margin-bottom: 10px;
        }
      }
      .bot{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn{
          button{
            margin-right: 10px;
          }
        }

        .op{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 14px;
          >div{
            margin-left: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            >img{
              margin-right: 5px;
              width: 20px;
              height: 20px;
            }
          }

        }
      }
    }
    .analysis{
      margin-top: 20px;
      padding:20px;
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;
      font-size: 16px;

      .answer{
        margin-bottom: 10px;

        .blue{
          color: #2d8cf0;
        }
        .green{
          color: #19be6b;
        }
        .red{
          color: #ed4014;
        }
      }
      .cont{
        //font-size: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        >span:nth-child(1){
          width: 50px;
        }
        >span:nth-child(2){
          flex: 1;
          width: 0;
          word-break: break-all;
          word-wrap: break-word;
          white-space: pre-wrap;
        }
      }
    }
    .check-similar-data{
      margin-top: 20px;
      display: inline-block;
      padding: 0 14px;
      height: 30px;
      background: #4A79FF;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
      cursor: pointer;
    }
  }
  .right{
    width: 400px;
    .right-top{
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;

      .tit{
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        font-weight: bold;
        border-bottom: 1px solid #dcdee2;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .again{
        white-space:normal
      }
      .answer-card{
        margin: 20px 10px;

        height: 380px;
        overflow-y: auto;
        position: relative;
        .list{
          padding-top: 4px;
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          >div{
            width: 50px;
            height: 30px;
            border: 1px solid #dcdee2;
            text-align: center;
            line-height: 30px;
            border-radius: 4px;
            margin: 0 10px 10px 10px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            >img{
              position: absolute;
              top:-4px;
              right: -7px;
            }
          }

          >div.error{
            background-color: #ec808d;
            color:#FFFFFF;
          }
          >div.corret,
          >div.complete{
            background-color: #19be6b;
            color:#FFFFFF;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            //border: 1px solid #2d8cf0;
          }
          >div.error.active{
            background-color:red;
            border: none;
          }
          >div.corret.active,
          >div.complete.active{
            background-color: green;
            border: none;
          }
          >div.mark{
            border: 1px solid #FF4D4F;

          }
        }
      }

    }
    .submit-papers{
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;
      margin-bottom: 20px;
      padding: 20px;
      text-align: center;

      .submit-btn{
        margin: 0 auto 15px auto;
        width: 100px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border:1px solid #19be6b;
        border-radius: 4px;
        cursor: pointer;
      }
      .submit-time{
        font-size: 30px;
        font-weight: bold;
        color:#ed4014;
      }
    }
    .exam-result{
      margin-bottom: 20px;
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;

      .exam-result-tit{
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        font-weight: bold;
        border-bottom: 1px solid #dcdee2;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .exam-result-cont{
        margin: 20px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .exam-result-cont-l{
          .exam-result-cont-l-item{
            margin-bottom: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            >p:nth-child(1){
              width: 65px;
              text-align: right;
              margin-right: 10px;
            }
            >p:nth-child(2){
              flex: 1;
              >span{
                color:#ed4014;
                font-size: 16px;
                font-weight: bold;
              }
            }
          }
        }
        .exam-result-cont-r{
          font-size: 20px;
          font-weight: bold;
        }
        .exam-result-cont-r.success{
          color:#19be6b;
        }
        .exam-result-cont-r.fail{
          color:#ed4014;
        }
      }
      .num{
        margin-top: 20px;
        margin-bottom: 10px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        span{
          padding-left:5px;
          padding-right: 2px;
          font-weight: bold;
        }

        .blue{
          color:#19be6b;
        }
        .red{
          color:#ed4014;
        }
      }
    }
  }
  .option{
    font-size: 16px;
    //white-space: -moz-pre-wrap;
    white-space:normal;
    height: auto;
  }
  .checkoutOption{
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
    white-space:normal;
    height: auto;
  }
}
.bjColor{
  background-color: #dee6de;
}
.nrBjColor{
  background-color: #f0faef !important;
}
.big-font-size{
  font-size: 18px !important;
}
.little-font-size{
  font-size: 14px!important;
}
.middle-font-size{
  font-size: 16px!important;
}
.correction{  //纠错
  height: 500px;
  overflow-y: auto;
  .type{
    font-size: 20px;
    margin-bottom: 20px;
  }
  .tit,
  .option-item,
  .analysis{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    .left{
      width: 100px;
      text-align: right;
      font-size: 14px;
      overflow: hidden;


      .correct{
        margin: 5px 0;
        border: 1px solid #dcdee2;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        cursor: pointer;
      }
      .dele{
        float: right;
        width: 50px;
        color:#FFFFFF;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        background-color: #ed4014;
        cursor: pointer;
      }
      .active{
        border: 1px solid #19be6b;
        background-color: #19be6b;
        color:#FFFFFF;
      }
    }
    .right{
      margin-left: 20px;
      flex: 1;
      border: 1px solid #dcdee2;

      .right-list{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >div{
          margin-right: 10px;
          border: 1px solid #dcdee2;
          border-radius: 4px;
          padding: 5px 10px;
          text-align: center;
          cursor: pointer;
        }
        .active{
          border: 1px solid #2d8cf0;
          background-color: #2d8cf0;
          color:#FFFFFF;
        }
      }
    }
  }
  .analysis{
    margin-top: 20px;
  }

}
.confirm-submit-txt{
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 10px 0;
}
.attention{
  .user-info{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    >div{
      margin-right: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >span{
        width: 40px;
      }
    }
  }
  .attention-tit{
    margin: 10px auto;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }
  .attention-cont{
    font-size: 14px;
    height: 400px;
    overflow-y: auto;
    line-height: 25px;
    >p{
      margin-bottom: 10px;
    }
  }
}
.cont-input{
  margin-bottom: 20px;
}
.next-btn{
  margin-top: 20px;
  display: inline-block;
  padding: 0 14px;
  height: 30px;
  background: #4A79FF;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 30px;
  cursor: pointer;
}
</style>
<style>
.train .ivu-checkbox-disabled+span{
  color:#515a6e !important;
}
/*.train-img-w img{*/
/*  width: 100%;*/
/*}*/
</style>
